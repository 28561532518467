import { useEffect, useState } from "react";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import Accordion from 'react-bootstrap/Accordion';
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

export default function ReportDashboard() {
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState([]);

    useEffect(() => {
        if(((user?.loggedAs !== "SuperAdmin") )) {
            navigate('/');
        }
        if(user?.brandId !== null) {
            getData();
        } else {
            navigate('/'); 
        }
    }, [])

    const getData = async () => {
        try {
          const response = await axios.get(`/api/getData?brandId=${user?.brandId}`);
          if (response.data.success) {
            setData(response.data.data);
          } else {
            Swal.fire({
              icon: "error",
              title: "Failed to fetch data",
              text: response.data.message || "Something went wrong!",
            });
          }
        } catch (err) {
          console.error("Error fetching region data:", err);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "Unable to fetch region data. Please try again later.",
          });
        }
    };
    return (
        <>
            <Loader loading={loading} />
            <Header />
            <div className="" style={{ backgroundColor: '#dae8d8' }}>
                <section className="main-content ss-content">
                    <div className="container">
                        {/* <Nav /> */}
                        <div className="card bg-CardColor border-0 mb-3">
                            <div className="card-body px-4">
                                <div className="row">
                                    <div className="col-md-12 mb-3">
                                        <div className="d-flex align-items-center justify-content-between tabHeading">
                                            <h3 className="d-lg-block d-none"><span className="main_bank-span">{user?.brand?.name || ''}</span></h3>
                                            <h3 className="d-lg-none d-md-block"><span className="main_bank-span">{user?.brand?.name || ''}</span><br/> </h3>
                                            <div className="text-end d-flex align-items-center justify-content-between">
                                                <h5 className="mb-0">
                                                    <span className="main_bank-span">Total Count: </span>
                                                    <span className="main_bank-span">{data?.totals || 0}</span>
                                                </h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Accordians */}
                                <Accordion defaultActiveKey={['0', '1', '2', '3']} alwaysOpen >
                                    <Accordion.Item eventKey="0" className="accordian active">
                                        <Accordion.Header>Region</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row">
                                                <div className={`${data?.otherRegions && data.otherRegions.length > 0 ? "col-lg-4 col-md-4 col-4" : "col-lg-6 col-md-6 col-6"}"mb-md-3 mb-0"`}>
                                                    <div className="card card-border-shadow-primary h-100">
                                                        <div className="card-body ss-cardBody">
                                                            <div className="card-title d-flex align-items-baseline justify-content-between">
                                                                <h6 className="d-block fw-12 mb-0">North Island Regions</h6>
                                                            </div>
                                                            <div className="card-details">
                                                                {data?.northIsland && data?.northIsland.length > 0 && (
                                                                    data.northIsland.map((northIslandRegion) => (
                                                                        <div className="ss-flex" key={northIslandRegion.region}>
                                                                            <p>{northIslandRegion?.region || ''}</p>
                                                                            <p className="fs-right">{northIslandRegion?.count || ''}</p>
                                                                        </div>
                                                                    ))
                                                                )} 
                                                                <div className="ss-flex">
                                                                    <p className="fw-bold">Total</p>
                                                                    <p className="fs-right fw-bold">
                                                                        {data?.northIsland && data.northIsland.length > 0
                                                                            ? data.northIsland.reduce((sum, region) => sum + (region.count || 0), 0)
                                                                            : 0}
                                                                    </p>
                                                                </div>
                                                                {!(data?.northIsland && data.northIsland.length > 0) && (
                                                                    <p>No data available for North Island.</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={`${data?.otherRegions && data.otherRegions.length > 0 ? "col-lg-4 col-md-4 col-4" : "col-lg-6 col-md-6 col-6"}"mb-md-3 mb-0"`}>
                                                    <div className="card card-border-shadow-primary h-100">
                                                        <div className="card-body ss-cardBody">
                                                            <div className="card-title d-flex align-items-baseline justify-content-between">
                                                                <h6 className="d-block fw-12 mb-0">South Island Regions</h6>
                                                            </div>
                                                            <div className="card-details">
                                                                {data?.southIsland && data.southIsland.length > 0 && (
                                                                    data.southIsland.map((southIslandRegion) => (
                                                                        <div className="ss-flex" key={southIslandRegion.region}>
                                                                            <p>{southIslandRegion?.region || ''}</p>
                                                                            <p className="fs-right">{southIslandRegion?.count || ''}</p>
                                                                        </div>
                                                                    ))
                                                                )} 
                                                                <div className="ss-flex">
                                                                    <p className="fw-bold">Total</p>
                                                                    <p className="fs-right fw-bold">
                                                                        {data?.southIsland && data.southIsland.length > 0
                                                                            ? data.southIsland.reduce((sum, region) => sum + (parseFloat(region.count) || 0), 0)
                                                                            : 0}
                                                                    </p>
                                                                </div>
                                                                {!(data?.southIsland && data.southIsland.length > 0) && (
                                                                    <p>No data available for South Island.</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {data?.otherRegions && data.otherRegions.length > 0 && 
                                                    <div className="col-lg-4 col-md-4 col-4 mb-md-3 mb-0">
                                                        <div className="card card-border-shadow-primary h-100">
                                                            <div className="card-body ss-cardBody">
                                                                <div className="card-title d-flex align-items-baseline justify-content-between">
                                                                    <h6 className="d-block fw-12 mb-0">Other Regions</h6>
                                                                </div>
                                                                <div className="card-details">
                                                                    {data?.otherRegions && data.otherRegions.length > 0 && (
                                                                        data.otherRegions.map((othersRegion) => (
                                                                            <div className="ss-flex" key={othersRegion.region}>
                                                                                <p>{othersRegion?.region || ''}</p>
                                                                                <p className="fs-right">{othersRegion?.count || ''}</p>
                                                                            </div>
                                                                        ))
                                                                    )} 
                                                                    <div className="ss-flex">
                                                                        <p className="fw-bold">Total</p>
                                                                        <p className="fs-right fw-bold">
                                                                            {data?.otherRegions && data.otherRegions.length > 0
                                                                                ? data.otherRegions.reduce((sum, region) => sum + (parseFloat(region.count) || 0), 0)
                                                                                : 0}
                                                                        </p>
                                                                    </div>
                                                                    {!(data?.otherRegions && data.otherRegions.length > 0) && (
                                                                        <p>No data available for other Regions.</p>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" className="accordian active">
                                        <Accordion.Header>Farm Type</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-6 mb-md-3 mb-0">
                                                    <div className="card card-border-shadow-primary h-100">
                                                        <div className="card-body ss-cardBody">
                                                            <div className="card-title d-flex align-items-baseline justify-content-between">
                                                                <h6 className="d-block fw-12 mb-0"></h6>
                                                            </div>
                                                            <div className="card-details">
                                                                {data?.typeOfFarm && data.typeOfFarm.length > 0 && (
                                                                    data.typeOfFarm.map((farmType) => (
                                                                        <div className="ss-flex" key={farmType.type_of_farm}>
                                                                            <p>{(farmType?.type_of_farm === '' ? 'Null' : farmType?.type_of_farm) || ''}</p>
                                                                            <p className="fs-right">{farmType?.count || ''}</p>
                                                                        </div>
                                                                    ))
                                                                )} 
                                                                <div className="ss-flex">
                                                                    <p className="fw-bold">Total</p>
                                                                    <p className="fs-right fw-bold">
                                                                        {data?.typeOfFarm && data.typeOfFarm.length > 0
                                                                            ? data.typeOfFarm.reduce((sum, farmType) => sum + (parseFloat(farmType.count) || 0), 0)
                                                                            : 0}
                                                                    </p>
                                                                </div>
                                                                {!(data?.typeOfFarm && data.typeOfFarm.length > 0) && (
                                                                    <p>No data available for Farm Type.</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="2" className="accordian active">
                                        <Accordion.Header>Farm Size</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-6 mb-md-3 mb-0">
                                                    <div className="card card-border-shadow-primary h-100">
                                                        <div className="card-body ss-cardBody">
                                                            <div className="card-title d-flex align-items-baseline justify-content-between">
                                                                <h6 className="d-block fw-12 mb-0"></h6>
                                                            </div>
                                                            <div className="card-details">
                                                                {data?.farmSize && data.farmSize.length > 0 && (
                                                                    data.farmSize.map((farmSize) => (
                                                                        <div className="ss-flex" key={farmSize.farm_size}>
                                                                            <p>{(farmSize?.farm_size === '' ? 'Null' : farmSize?.farm_size) || ''}</p>
                                                                            <p className="fs-right">{farmSize?.count || ''}</p>
                                                                        </div>
                                                                    ))
                                                                )}
                                                                <div className="ss-flex">
                                                                    <p className="fw-bold">Total</p>
                                                                    <p className="fs-right fw-bold">
                                                                        {data?.farmSize && data.farmSize.length > 0
                                                                            ? data.farmSize.reduce((sum, farmSize) => sum + (parseFloat(farmSize.count) || 0), 0)
                                                                            : 0}
                                                                    </p>
                                                                </div>
                                                                {!(data?.farmSize && data.farmSize.length > 0) && (
                                                                    <p>No data available for Farm Size.</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" className="accordian active">
                                        <Accordion.Header>Age</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-6 mb-md-3 mb-0">
                                                    <div className="card card-border-shadow-primary h-100">
                                                        <div className="card-body ss-cardBody">
                                                            <div className="card-title d-flex align-items-baseline justify-content-between">
                                                                <h6 className="d-block fw-12 mb-0"></h6>
                                                            </div>
                                                            <div className="card-details">
                                                                {data?.age && data.age.length > 0 && (
                                                                    data.age.map((age) => (
                                                                        <div className="ss-flex" key={age.age_group}>
                                                                            <p>{(age?.age_group === '' ? 'Null' : age?.age_group) || ''}</p>
                                                                            <p className="fs-right">{age?.count || ''}</p>
                                                                        </div>
                                                                    ))
                                                                )}
                                                                <div className="ss-flex">
                                                                    <p className="fw-bold">Total</p>
                                                                    <p className="fs-right fw-bold">
                                                                        {data?.age && data.age.length > 0
                                                                            ? data.age.reduce((sum, age) => sum + (parseFloat(age.count) || 0), 0)
                                                                            : 0}
                                                                    </p>
                                                                </div>
                                                                {!(data?.age && data.age.length > 0) && (
                                                                    <p>No data available for Age.</p>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}