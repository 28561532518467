import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataTableComp } from "../Common/Datatable";
import { Tooltip } from '@mui/material';
import axios from "axios";
import Swal from "sweetalert2";
import Add from "./Add";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";
import AddParameters from "./AddParameters";
import AddCompetitors from "./AddCompetitors";
import Switch from '@mui/material/Switch';

export default function List(){
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);
    const [show, setShow] = useState(false);
    const [showParameters, setShowParameters] = useState(false);
    const [showCompetitors, setShowCompetitors] = useState(false);
    const [editId, setEditId] = useState(0);
    const [popupType, setPopupType] = useState('Add');
    const [brands, setBrands] = useState([]);
    const [brandId, setBrandId] = useState(null);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const [sortDirection, setSortDirection] = useState('asc');

    useEffect(() => {
        if(user.role !== 'SuperAdmin') {
            navigate('/');
        }
        getBrandsList();
    },[])

    const getBrandsList = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/brands`);
            setLoading(false)
            if (response.data.success) {
                setBrands(response.data.data);
            } else {
                Swal.fire({
                    icon: 'error',
                    text: response.data.message || 'An error occurred while fetching brand list!',
                });
            }
        } catch (err) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Server Error',
                text: 'Failed to connect to the server. Please try again later.',
            });
        }
    };

    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }

    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
        setShowParameters(false);
        setShowCompetitors(false);
    }
    
    const handleEditParameters = (id, brandId) => {
        setEditId(id);
        setPopupType('Edit');
        setShowParameters(true);
        setBrandId(brandId)
    }

    const handleEditCompetitors = (id, brandId) => {
        setEditId(id);
        setPopupType('Edit');
        setShowCompetitors(true);
        setBrandId(brandId)
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#087f43',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
          }).then((result) => {
            if (result.isConfirmed) { 
                axios.delete(`/api/brands/delete?id=${id}`).then((res) =>{
                    if(res.data.success){                             
                        Swal.fire('Deleted!', '', 'success')
                        getBrandsList();
                    } 
                }).catch((error) => {
                    console.log(error);                     
                    Swal.fire(error.message, '', 'info')           
                });
            } else if (result.isDenied) {
              Swal.fire('Cancelled!', '', 'info')
            }
        }) 
    }

    const handleStatus = async (e, id) => {
        const status = e.target.checked ? '1' : '0';
        try {
            await axios.put(`api/brands/updateStatus?id=${id}`, { status });

        } catch (error) {
            console.error("Error:", error);
            Swal.fire(error.message, '', 'error');
        }
    };
    
    const handleUserLogin = async (email) => {
        try {
            const response = await axios.post('/api/auth/brand/another/login', { 
                email: email, 
                loggedAs: user?.loggedAs, 
                loggedAsId: user?.loggedAsId 
            });
    
            if (response.data.success) {
                localStorage.clear();
                localStorage.setItem('ag-authUser', JSON.stringify(response.data.data));
                navigate('/performance');
            } else {
                Swal.fire(response.data.message, '', 'info');
            }
        } catch (error) {
            Swal.fire(error.response?.data?.message || 'An error occurred. Please try again.', '', 'info');
        }
    };

    const handleSort = (column, sortDirection) => {
        // console.log(sortDirection)
        setSortDirection(sortDirection)
    };

    const columns = [
        {
            name: 'Brand Name',
            selector: row => row.name,
            sortable: true,            
            width: '14%'
        },
        {
            name: 'Admin Name',
            selector: row => row.brand_users[0].firstName,
            sortable: true,            
            width: '14%'
        },
        {
            name: 'Email',
            selector: row => row.brand_users[0].email,
            sortable: true,
            width: '28%'
        },
        // {
        //     name: 'Phone',
        //     selector: row => row.brand_users[0].phone,
        //     sortable: true,
        // },
        {
            name: <div>Manage Parameters</div>,
            cell: row => (
                <div className="d-flex align-items-center">
                    <Tooltip title="Manage Parameters" size='small' className='icon icon-sm icon-secondary'>
                        <Link className="" onClick={() => handleEditParameters(row.brand_users[0].id, row.id)}>
                            <div className="table-icon bg-success-subtle">
                                <i className="fa-solid fa-pen-to-square text-success"></i>
                            </div>
                        </Link>
                    </Tooltip>
                </div>
                
            ),  
            width: '10%'
        },
        {
            name: <div>Manage Competitors</div>,
            cell: row => (
                <div className="d-flex align-items-center">
                    <Tooltip title="Manage Competitors" size='small' className='icon icon-sm icon-primary ms-2'>
                        <Link className="mx-2" onClick={() => handleEditCompetitors(row.brand_users[0].id, row.id)}>
                            <div className="table-icon bg-success-subtle">
                                <i className="fa-solid fa-pen-to-square text-success"></i>
                            </div>
                        </Link>
                    </Tooltip>
                </div>
                
            ),  
            width: '10%'
        },
        {
            name: 'Status',
            cell: row => (
                <Switch
                    className="ss-switch"
                    defaultChecked={row.brand_users[0].status === '1' ? true : false}
                    onClick={(e) => handleStatus(e, row.brand_users[0].id)}
                />
                // <div className="form-check form-switch mt-2">
                //     <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={row.brand_users[0].status === '1' ? true : false} onClick={(e) => handleStatus(e, row.brand_users[0].id)}/>
                // </div>
            ),   
            width: '6%'        
        },
        {
            name: 'Action',
            cell: row => (
                <div className="d-flex align-items-center">
                    {/*<Link className="mx-2" >
                        <div className="material-switch pull-right">
                            <input 
                                id="flexSwitchCheckDefault" 
                                type="checkbox" 
                                defaultChecked={row.brand_users[0].status === '1' ? true : false} 
                                onClick={(e) => handleStatus(e, row.brand_users[0].id)}
                            />
                            <label htmlFor="active1" className="label-default"></label>
                        </div>
                    </Link> */}
                    <Tooltip title="Edit" size='small' className='icon icon-sm icon-secondary'>
                        <Link className="" onClick={() => handleEdit(row.brand_users[0].id)}>
                            <div className="table-icon bg-success-subtle">
                                <i className="fa-solid fa-pen-to-square text-success"></i>
                            </div>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Delete" size='small' className='icon icon-sm icon-primary ms-2'>
                        <Link className="mx-2" onClick={() => handleDelete(row.brand_users[0].id)}>
                            <div className="table-icon bg-danger-subtle">
                                <i className="fa-solid fa-trash-can text-danger"></i>
                            </div>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Login as brand" size='small' className='icon icon-sm icon-secondary'>
                        <Link className="mx" onClick={(e) =>  {handleUserLogin(row.brand_users[0].email)}} >
                            <div className="table-icon bg-primary-subtle">
                                <i className="fa-solid fa-right-to-bracket text-primary"></i>
                            </div>
                        </Link>
                    </Tooltip>
                </div>
                
            ),    
            width: '10%'
        }
    ];

    return(
        <>
        <Loader loading={loading} />
        <Header pageTitle="Brands"/>
        <section className="main-content">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-8 col-9 mb-3">
                        <div className="heading">Brands</div>
                    </div>
                    <div className="col-md-4 mb-3 d-none d-md-block text-md-end">
                        <button type="button" className="add-btn" onClick={handleAdd}>
                            <i className="fa-solid fa-plus me-2"></i>Add Brand
                        </button>
                    </div>
                    <div className="col-md-3 col-3 d-md-none d-sm-block mb-3 text-end">
                        <a href="#" className="add-btn" onClick={handleAdd}>
                            <i className="fa-solid fa-plus"></i>
                        </a>
                    </div>
                </div>
                <div className="card border-0">
                    <div className="card-body">
                        <div className="row align-items-center">
                            <div className="col-md-12">
                                <div className="table-responsive">
                                    <DataTableComp columns={columns} data={brands} defaultSortFieldId={1} onSort={handleSort} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <Add 
            type={popupType} 
            editId={editId} 
            setEditId={setEditId} 
            show={show} 
            setShow={setShow}
            showParameters={showParameters}
            setShowParameters={setShowParameters}
            loading={loading} 
            setLoading={setLoading}
            getList={getBrandsList}
            brandId={brandId}
            setBrandId={setBrandId}
        />
        <AddParameters
            type={popupType}
            editId={editId} 
            setEditId={setEditId} 
            show={showParameters}
            setShow={setShowParameters}
            showCompetitors={showCompetitors}
            setShowCompetitors={setShowCompetitors}
            loading={loading}
            setLoading={setLoading}
            getList={getBrandsList}
            brandId={brandId}
            setBrandId={setBrandId}
        />
        <AddCompetitors
            type={popupType}
            editId={editId} 
            setEditId={setEditId} 
            show={showCompetitors}
            setShow={setShowCompetitors}
            loading={loading}
            setLoading={setLoading}
            getList={getBrandsList}
            brandId={brandId}
            setBrandId={setBrandId}
        />
        </>
    )
}