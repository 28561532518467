import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { DataTableComp } from "../Common/Datatable";
import { Switch, Tooltip } from '@mui/material';
import axios from "axios";
import Swal from "sweetalert2";
import Add from "./Add";
import Header from "../Header/Header";
import Loader from "../Loader/Loader";

export default function List() {
    const [show, setShow] = useState(false);
    const [editId, setEditId] = useState(0);
    const [popupType, setPopupType] = useState('Add');
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const authUser = localStorage.getItem('ag-authUser');
    const user = JSON.parse(authUser);
    const brandId = user.brandId;
    const [sortDirection, setSortDirection] = useState('asc');
    const navigate = useNavigate();

    useEffect(() => {
        if(user?.role !== 'SuperAdmin' && user?.role !== 'BrandAdmin') {
            navigate('/performance');
        }
        getList();
    },[])

    const getList = async () => {
        setLoading(true)
        try {
            const response = await axios.get(`/api/users?role=User&brandId=${brandId}`);
            setLoading(false)
            if (response.data.success) {
                setUsers(response.data.data);
            } else {
                Swal.fire({
                    icon: 'error',
                    text: response.data.message || 'An error occurred while fetching user list!',
                });
            }
        } catch (err) {
            setLoading(false)
            Swal.fire({
                icon: 'error',
                title: 'Server Error',
                text: 'Failed to connect to the server. Please try again later.',
            });
        }
    };

    const handleUserLogin = async (email) => {
        try {
            const response = await axios.post('/api/auth/brand/another/login', { 
                email: email, 
                loggedAs: user?.loggedAs, 
                loggedAsId: user?.loggedAsId 
            });
    
            if (response.data.success) {
                localStorage.clear();
                localStorage.setItem('ag-authUser', JSON.stringify(response.data.data));
                navigate('/performance');
            } else {
                Swal.fire(response.data.message, '', 'info');
            }
        } catch (error) {
            Swal.fire(error.response?.data?.message || 'An error occurred. Please try again.', '', 'info');
        }
    };

    const handleAdd = () => {
        setEditId(0);
        setPopupType('Add');
        setShow(true);
    }

    const handleEdit = (id) => {
        setEditId(id);
        setPopupType('Edit');
        setShow(true);
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure you want to delete?',
            showCancelButton: true,
            confirmButtonColor: '#087f43',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                axios.delete(`/api/users/delete?id=${id}`).then((res) => {
                    if (res.data.success) {
                        Swal.fire('Deleted!', '', 'success')
                        getList();
                    }
                }).catch((error) => {
                    console.log(error);
                    Swal.fire(error.message, '', 'info')
                });
            } else if (result.isDenied) {
                Swal.fire('Cancelled!', '', 'info')
            }
        })
    }

    const handleStatus = async (e, id) => {
        const status = e.target.checked ? '1' : '0';
        console.log("e", e);
        try {
            await axios.put(`api/users/updateStatus?id=${id}`, { status });
        } catch (error) {
            console.error("Error:", error);
            Swal.fire(error.message, '', 'error');
        }
    };

    const handleSort = (column, sortDirection) => {
        // console.log(sortDirection)
        setSortDirection(sortDirection)
    };

    const columns = [
        {
            name: 'Brand',
            selector: row => row.brand.name,
            sortable: true,
            width: '13%'
        },
        {
            name: 'User Name',
            selector: row => `${row.firstName} ${row.lastName}`,
            sortable: true,
            width: '15%'
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
            width: '25%'
        },
        // {
        //     name: 'Phone',
        //     selector: row => row.phone,
        //     sortable: true,
        // width: '13%'
        // },
        {
            name: 'Designation',
            selector: row => row.designation,
            sortable: true,
            width: '13%'
        },
        {
            name: 'Status',
            cell: row => (
                // <div className="form-check form-switch mt-2">
                //     <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={row.status === '1' ? true : false} onClick={(e) => handleStatus(e, row.id)}/>
                // </div>
                <Switch
                    className="ss-switch"
                    defaultChecked={row.status === '1' ? true : false}
                    onClick={(e) => handleStatus(e, row.id)}
                />
            ),
            width: '13%'
        },
        {
            name: 'Action',
            cell: row => (
                <div className="d-flex align-items-center">
                    {/*<Link >
                        <div className="material-switch pull-right">
                            <input 
                                id="flexSwitchCheckDefault" 
                                name="someSwitchOption001"
                                type="checkbox" 
                                defaultChecked={row.status.id === '1' ? true : false} 
                                onClick={(e) => handleStatus(e, row.id)}
                            />
                            <label htmlFor="active1" className="label-default"></label>
                        </div>
                    </Link> */}
                    <Tooltip title="Edit" size='small' className='icon icon-sm icon-secondary'>
                        <Link className="" onClick={() => handleEdit(row.id)}>
                            <div className="table-icon bg-success-subtle">
                                <i className="fa-solid fa-pen-to-square text-success"></i>
                            </div>
                        </Link>
                    </Tooltip>
                    <Tooltip title="Delete" size='small' className='icon icon-sm icon-primary ms-2'>
                        <Link className="mx-2" onClick={() => handleDelete(row.id)}>
                            <div className="table-icon bg-danger-subtle">
                                <i className="fa-solid fa-trash-can text-danger"></i>
                            </div>
                        </Link>
                    </Tooltip>
                    {/* <Tooltip title="Login as User" size='small' className='icon icon-sm icon-secondary'>
                        <Link className="mx" onClick={(e) =>  {handleUserLogin(row.email)}} >
                            <div className="table-icon bg-primary-subtle">
                                <i className="fa-solid fa-right-to-bracket text-primary"></i>
                            </div>
                        </Link>
                    </Tooltip> */}
                </div>

            ),
            // width: '13%'
        }
    ];

    return (
        <>
            <Loader loading={loading} />
            <Header pageTitle="Users" />
            <section className="main-content">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-8 col-9 mb-3">
                            <div className="heading">Users</div>
                        </div>
                        <div className="col-md-4 mb-3 d-none d-md-block text-md-end">
                            <button type="button" className="add-btn" onClick={handleAdd}>
                                <i className="fa-solid fa-plus me-2"></i>Add User
                            </button>
                        </div>
                        <div className="col-md-3 col-3 d-md-none d-sm-block mb-3 text-end">
                            <a href="#" className="add-btn" onClick={handleAdd}>
                                <i className="fa-solid fa-plus"></i>
                            </a>
                        </div>
                    </div>
                    <div className="card border-0">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-md-12">
                                    <div className="table-responsive">
                                        <DataTableComp columns={columns} data={users} defaultSortFieldId={1} onSort={handleSort} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Add
                type={popupType}
                editId={editId}
                setEditId={setEditId}
                show={show}
                setShow={setShow}
                loading={loading}
                setLoading={setLoading}
                getList={getList}
            />
        </>
    );
}